<template>
	<div class="pass-form">
		<div class="row text-left m-4">
	      <a @click.prevent="() => {
			  $router.push('/login').catch(() => {});
			  }" class="back-btn"><i class="far fa-arrow-alt-circle-left"></i> Back to login</a>
	    </div>
		<form @submit.prevent="forgotPassword" v-if="!verificationCodeSent">
			<img class="mb-4" src="../../assets/img/mdme-logo.png" width="80" height="80">
			<div class="form-group">
				<input name="email" placeholder="Enter your email: " type="email" required class="form-control form-control-lg" v-model="form.email" />
			</div>
			<button v-if="!loading" class="btn-outline-blue" @click.stop.prevent="forgotPassword">
				Get Verification Code
			</button>
			<p v-else><b-spinner small type="grow" class="spinner"></b-spinner> Loading...</p>
		</form>
		<form @submit.prevent="resetPassword" v-else>
			<img class="mb-4" src="../../assets/img/mdme-logo.png" width="80" height="80">
			<h3>
				We've sent you a verification code, please check your email and enter it
				below along with your new password.
			</h3>
			<div class="form-group">
				<input name="code" placeholder="Verification Code:" required class="form-control form-control-lg" v-model="form.code" />
			</div>
			<div class="form-group">
				<input name="pass1" placeholder="New password:" type="password" autocomplete="new-password" required class="form-control form-control-lg" v-model="form.pass1" />
			</div>
			<div class="form-group">
				<input name="pass2" placeholder="Re-enter new password:" required type="password" class="form-control form-control-lg" v-model="form.pass2" />
			</div>
			<template v-if="!loading">
				<button type="submit" class="btn-blue" @click.stop.prevent="resetPassword" >
					Reset Password
				</button>
			</template>
			<template v-else>
				<p><b-spinner small type="grow" class="spinner"></b-spinner> Loading...</p>
			</template>
		</form>
		<a class="pass-link" @click.stop.prevent="$router.push('/login').catch(() => {})">Got here by mistake? Click to login.</a>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					email: '',
					code: '',
					pass1: '',
					pass2: '',
				},
				verificationCodeSent: false,
				loading: false
			};
		},
		methods: {
			async forgotPassword() {
				this.loading = true;
				try {
					if (await this.$store.dispatch('forgotPassword', this.form.email)) {
						this.verificationCodeSent = true;
					}
				} finally {
					this.loading = false;
				}
			},
			async resetPassword() {
				try {
					this.loading = true;
					const { email, code, pass1, pass2 } = this.form;
					if (pass1 != pass2){
						alert('Please ensure your passwords match.');
					} else if (pass1.length < 8 || pass2.length < 8){
						alert('Please ensure your password is at least 8 characters long');
					} else {
						await this.$store.dispatch('passwordSubmit', {
							email: email,
							code: code,
							password: pass1
						});
					}
				} finally {
					this.loading = false;
				}
			},
		},
	};
</script>
